<template>
  <div class="notice"></div>
</template>

<script>
export default {
  name: "notice",
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>
<style>
.notice {
}
</style>
